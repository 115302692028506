// application variables via callback
@import 'app/_variables.scss';

// common mixins
@import 'common/_mixins.scss';

// the rest of Bootstrap (at least the parts we use)
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
//@import 'node_modules/bootstrap/scss/images';
//@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
//@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
//@import 'node_modules/bootstrap/scss/badge';
//@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
//@import 'node_modules/bootstrap/scss/media';
//@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
//@import 'node_modules/bootstrap/scss/popover';
//@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/print';

html {
  // apply a natural box layout model to all elements, but allowing components to change
  box-sizing: border-box;

  // smooth scrolling
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  // site background color
  background-color: white;

  // black text by default
  color: black;

  // use system fonts
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,
    “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
    sans-serif;

  // let components set their own margins
  margin: 0px;

  // enforce a reasonable minimum
  min-width: 320px;

  // always show a scrollbar to prevent jarring resizing
  overflow-y: scroll;

  // prevent shift of body content on modal open
  padding-right: 0px !important;
}

// for our sticky footer
body,
wrapper {
  min-height: 100vh;
}

// FSP color
.fsp-background {
  background-color: $secondary;
}

// main content
.fsp-main {
  // sit below the header
  padding-top: $header-height + 15px;

  // sit above the footer
  padding-bottom: 35px;

  // constrain the width; it looks too sparse on large screens otherwise
  max-width: $site-width;

  // enforce a minimum height to not crowd the ribbon
  min-height: 450px;

  // center it
  margin-left: auto;
  margin-right: auto;
}

// override max width for printing
@media print {
  .fsp-main {
    max-width: 100%;
  }
}

// page content (this is the main content less header and footer)
.fsp-page {
  // give it a little space
  margin-left: 6px;
  margin-right: 6px;
}

// mobile overrides; these should match the *-small settings
@media (max-width: 425px) {
  // main content
  .fsp-main {
    // sit below the header
    padding-top: $small-header-height + 15px;
  }
}

// print overrides
@media print {
  .fsp-main {
    // no padding
    padding-top: 0;
    padding-bottom: 0;
  }
}

// a little space after cards
.card {
  margin-bottom: 10px;
}

// buttons
.btn:focus {
  // disable the box shadow
  box-shadow: none;
}
.btn-primary:disabled {
  background-color: $gray-600;
  border-color: $gray-600;
  color: black;
}

// link; useful when an <a> tag is not an option
.fsp-link {
  cursor: pointer;
  color: black;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// inline links; these are links that are embedded
// within other text and should therefore stand out
.fsp-inline-link {
  font-weight: $font-weight-bold;
  cursor: pointer;
}
.fsp-inline-link:hover {
  text-decoration: underline;
}

// other clickable things
.fsp-clickable {
  cursor: pointer;
}

// disabled things
.fsp-disabled {
  cursor: not-allowed;
  color: $input-disabled-bg;
}

// prevent selection
.fsp-noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// outdated browser warning
.fsp-outdated {
  // sit on top of everything
  z-index: 5000;
}
#outdated .last {
  top: 4px;
  right: 4px;
}

// reCAPTCHA
.g-recaptcha {
  display: inline-block;
  margin-top: 20px;
}

// flexbox addition
.flex-grow {
  flex: 1 0 auto;
}

// links
a {
  color: black;
}

// tweak a few things when nested in tabs
.tab-content section:first-child {
  // no top margin
  margin-top: 0;
}
.tab-content .fsp-form {
  // no padding
  padding: 0;
}

// a little padding around sections
section {
  margin: 16px 0 16px 0;
}

// instructions
.fsp-instructions {
  font-size: $font-size-md;
}
.fsp-instructions p:last-child {
  // no bottom margin
  margin-bottom: 0px;
}

// entity lists
.fsp-entity-list {
  font-size: $font-size-xl;
  cursor: pointer;
}
.fsp-inline-link.fsp-entity-list {
  font-weight: normal;
}

// expandable items
.fsp-expandable {
  font-size: $font-size-xl;
  cursor: pointer;
  margin-left: 5px;
}
.fsp-expandable-active {
  // rotate
  @include rotate(90deg);

  // animation magic for changes
  @include transition(all 0.4s ease-in-out);
}
.fsp-expandable-inactive {
  // animation magic for changes
  @include transition(all 0.4s ease-in-out);
}

// section header
.fsp-section-header {
  // a little bigger
  font-size: $h2-font-size;

  // a little more colorful
  color: $green;
}
.fsp-section-header p {
  // cut the bottom margin
  margin-bottom: 0.2rem;
}

// narrow lists
.fsp-list-narrow {
  padding-left: 20px;
}

// widget controls
.fsp-widget-control {
  // clickable
  cursor: pointer;
  font-size: smaller;

  // offset it
  border: 1px solid $gray-400;
  border-radius: 4px;

  // give it some space
  padding: 4px;
}
.fsp-widget-controls .fsp-widget-control:not(:first-child) {
  margin-left: 4px;
}

// opaque
.fsp-opaque {
  opacity: 0;
}

// small browser overrides
@media (max-width: 425px) {
  // section header
  .fsp-section-header {
    font-size: $h3-font-size;
  }

  // expandable items
  .fsp-expandable {
    font-size: $font-size-lg;
  }
}

// card breakpoints
.card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(xs) {
    column-count: 1;
  }
}

// Facebook buttons
.kep-login-facebook {
  // not all caps
  text-transform: none !important;

  // match other buttons
  border-radius: 0.3rem;
}
.kep-login-facebook:focus {
  // no outline
  outline: none;
}

// star ratings
.star-ratings {
  .star-container:not(:first-of-type) {
    padding-left: 4px !important;
  }
  .star-container:not(:last-of-type) {
    padding-right: 4px !important;
  }
}
.star-rating-required {
  .star-ratings {
    .star-container {
      .star {
        fill: rgb(203, 211, 227) !important;
        stroke: $red;
      }
      .widget-svg.hovered {
        path {
          fill: $blue !important;
        }
      }
    }
  }
}
