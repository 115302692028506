// FSP's colors
$red: #e51a38;
$yellow: #ffd350;
$green: #6fb640;
$blue: #7cabd8;
$orange: #ffa15b;
$purple: #6f619c;

// theme colors
$primary: $yellow;
$secondary: $blue;

// square off progress bars
$progress-border-radius: 0;

// Bootstrap functions and variables
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

// Bootstrap-specific app callback
@import 'app/bootstrap.scss';

// tweak paging colors
$pagination-color: black;
$pagination-hover-color: black;
$pagination-hover-bg: $secondary;
$pagination-hover-border-color: $secondary;
$pagination-active-color: black;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

// shrink breadcrumb padding
$breadcrumb-padding-y: 0.3rem;

// alter breadcrumb colors
$breadcrumb-bg: $gray-400;
$breadcrumb-active-color: $gray-700;

// some Bootstrap-esque additions
$font-size-md: ($font-size-base * 1.05);
$font-size-xl: ($font-size-base * 1.5);
$font-size-xxl: ($font-size-base * 2);

// for small checkboxes
$form-check-input-sm-gutter: 2.35rem !default;
$form-check-input-sm-margin-y: 0.3rem !default;
$form-check-input-sm-margin-x: 0.25rem !default;

// for large checkboxes
$form-check-input-lg-gutter: 3.55rem !default;
$form-check-input-lg-margin-y: 0.2rem !default;
$form-check-input-lg-margin-x: 0.25rem !default;

// full header height
$header-height: 50px;

// small header height
$small-header-height: 30px;

// width for main content
$site-width: 900px;

// form input colors
$form-field-valid: $green;
$form-field-warning: $orange;
$form-field-error: $red;

// let modal content handle its own padding
$modal-inner-padding: 0;
