// transitions
@mixin transition($property) {
  -webkit-transition: $property;
  -ms-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}

// rotation
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -webkit-transform: rotate($degrees);
}
