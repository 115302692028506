//
// This file contains application-specific styles.
//

// import common styles
@import 'common/styles.scss';

// sticky, on top footer
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 900;
}

// make links stand out a little more
a,
.fsp-inline-link,
.fsp-link {
  color: $purple;
  text-decoration: underline;
  text-decoration-color: $purple;
  text-decoration-style: solid;
}

// but not breadcrumbs
.breadcrumb-item > a {
  color: black;
}
